import React from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
// import { ApolloProvider } from 'react-apollo';

import store from './src/store';
// import client from './src/apollo';
// <ApolloProvider client={client} />;
const App = ({ element }) => (
  <Provider store={store}>
    <div>
      <Helmet>
        <script type="text/javascript">
          {`window.intercomSettings = {
              app_id: "kq5xts07"
          };`}
        </script>
        <script type="text/javascript">{`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator'); ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments); };i.q=[];i.c=function(args){i.q.push(args); };w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/kq5xts07';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload', l); }else{w.addEventListener('load', l, false); }}})();`}</script>
      </Helmet>
      {element}
    </div>
  </Provider>
);
export default App;
