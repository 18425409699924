const SET_TODO = `SET_TODO`;
const SET_TODOS = `SET_TODOS`;
const initialState = {};

export const setTodo = (id, value) => ({
  payload: { id, value },
  type: SET_TODO
});

export const setTodos = value => ({ payload: value, type: SET_TODOS });

const reducer = (state = initialState, action) => {
  if (!action.payload) return state;
  const { payload } = action;
  switch (action.type) {
    case SET_TODO:
      return {
        ...state,
        [payload.id]: payload.value
      };
    case SET_TODOS:
      return payload;
    default:
      return state;
  }
};

export default reducer;
