module.exports = [{
      plugin: require('/Users/colinmcd94/Documents/Projects/bagel/web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/colinmcd94/Documents/Projects/bagel/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131542097-1","head":true,"cookieDomain":"bagel.md"},
    },{
      plugin: require('/Users/colinmcd94/Documents/Projects/bagel/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
