// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-baa-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/baa.js" /* webpackChunkName: "component---src-pages-baa-js" */),
  "component---src-pages-callback-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-compliance-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/compliance.js" /* webpackChunkName: "component---src-pages-compliance-js" */),
  "component---src-pages-index-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joined-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/joined.js" /* webpackChunkName: "component---src-pages-joined-js" */),
  "component---src-pages-optout-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/optout.js" /* webpackChunkName: "component---src-pages-optout-js" */),
  "component---src-pages-pdf-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/pdf.js" /* webpackChunkName: "component---src-pages-pdf-js" */),
  "component---src-pages-print-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-privacy-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-schedule-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-security-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-todo-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo.js" /* webpackChunkName: "component---src-pages-todo-js" */),
  "component---src-pages-todo-admin-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/admin.js" /* webpackChunkName: "component---src-pages-todo-admin-js" */),
  "component---src-pages-todo-business-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/business.js" /* webpackChunkName: "component---src-pages-todo-business-js" */),
  "component---src-pages-todo-clia-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/clia.js" /* webpackChunkName: "component---src-pages-todo-clia-js" */),
  "component---src-pages-todo-hipaa-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/hipaa.js" /* webpackChunkName: "component---src-pages-todo-hipaa-js" */),
  "component---src-pages-todo-incorporation-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/incorporation.js" /* webpackChunkName: "component---src-pages-todo-incorporation-js" */),
  "component---src-pages-todo-insurance-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/insurance.js" /* webpackChunkName: "component---src-pages-todo-insurance-js" */),
  "component---src-pages-todo-learn-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/learn.js" /* webpackChunkName: "component---src-pages-todo-learn-js" */),
  "component---src-pages-todo-legal-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/legal.js" /* webpackChunkName: "component---src-pages-todo-legal-js" */),
  "component---src-pages-todo-marketing-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/marketing.js" /* webpackChunkName: "component---src-pages-todo-marketing-js" */),
  "component---src-pages-todo-office-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/office.js" /* webpackChunkName: "component---src-pages-todo-office-js" */),
  "component---src-pages-todo-optout-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/optout.js" /* webpackChunkName: "component---src-pages-todo-optout-js" */),
  "component---src-pages-todo-osha-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/osha.js" /* webpackChunkName: "component---src-pages-todo-osha-js" */),
  "component---src-pages-todo-practice-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/practice.js" /* webpackChunkName: "component---src-pages-todo-practice-js" */),
  "component---src-pages-todo-pricing-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/pricing.js" /* webpackChunkName: "component---src-pages-todo-pricing-js" */),
  "component---src-pages-todo-scope-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/scope.js" /* webpackChunkName: "component---src-pages-todo-scope-js" */),
  "component---src-pages-todo-software-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/software.js" /* webpackChunkName: "component---src-pages-todo-software-js" */),
  "component---src-pages-todo-validation-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/validation.js" /* webpackChunkName: "component---src-pages-todo-validation-js" */),
  "component---src-pages-todo-vendors-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/vendors.js" /* webpackChunkName: "component---src-pages-todo-vendors-js" */),
  "component---src-pages-todo-website-js": () => import("/Users/colinmcd94/Documents/Projects/bagel/web/src/pages/todo/website.js" /* webpackChunkName: "component---src-pages-todo-website-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/colinmcd94/Documents/Projects/bagel/web/.cache/data.json")

