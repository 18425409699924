const reducerGenerator = (noun, initialState, clearState) => (state = initialState, action) => {
  switch (action.type) {
    case `SET_${noun.toUpperCase()}`:
      return action.payload;

    case `CLEAR_${noun.toUpperCase()}`:
      return clearState;

    default:
      return state;
  }
};

function setGenerator(noun) {
  const actionCreator = data => ({
    payload: data,
    type: `SET_${noun.toUpperCase()}`
  });
  return actionCreator;
}

const clearGenerator = noun => () => ({
  type: `CLEAR_${noun.toUpperCase()}`
});

function generate(noun, initialState = null, clearState = null) {
  return {
    REDUCER: reducerGenerator(noun, initialState, clearState),
    clear: clearGenerator(noun),
    set: setGenerator(noun)
  };
}

export default generate;
