import { combineReducers } from 'redux';

import meta from './meta';
import todoReducer from './todo.js';

let defaults = {};
if (typeof window !== 'undefined') {
  defaults.idToken = window.localStorage.getItem('idToken');
  defaults.accessToken = window.localStorage.getItem('accessToken');
  defaults.expiresAt = window.localStorage.getItem('expiresAt');
}

export const idTokenDuck = meta('idToken', defaults.idToken || '', '');
export const accessTokenDuck = meta('accessToken', defaults.accessToken || '', '');
export const expiresAtDuck = meta('expiresAt', defaults.expiresAt || 0, 0);

export const saveProgressModalDuck = meta('saveProgressModal', false, false);
export const pdfModalDuck = meta('pdfModal', false, false);
export const trelloModalDuck = meta('trelloModal', false, false);

const rootReducer = combineReducers({
  todos: todoReducer,
  idToken: idTokenDuck.REDUCER,
  accessToken: accessTokenDuck.REDUCER,
  expiresAt: expiresAtDuck.REDUCER,
  saveProgressModal: saveProgressModalDuck.REDUCER,
  pdfModal: pdfModalDuck.REDUCER,
  trelloModal: trelloModalDuck.REDUCER
});

export default rootReducer;
